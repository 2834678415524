import { render, staticRenderFns } from "./regulationsPopup.vue?vue&type=template&id=34cac362&scoped=true&"
import script from "./regulationsPopup.vue?vue&type=script&lang=js&"
export * from "./regulationsPopup.vue?vue&type=script&lang=js&"
import style0 from "./regulationsPopup.vue?vue&type=style&index=0&id=34cac362&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34cac362",
  null
  
)

export default component.exports