<template>
    <div class="popup-layout">
        <div class="popup popup--7">
            <div class="request">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 request__title">
                    Настойка часового пояса
                </p>

                <p class="colorFiord request__contentText">
                    Мы определили Ваш часовой пояс по
                    <b>Киеву (UTC +2.00)</b> и все события в обучении будут проходить
                    согласно часового пояса в этом городе.
                </p>

                <p class="colorFiord request__contentText">
                    Вы можете выставить часовой пояс вручную или в будущем изменить его,
                    обратившись в поддержку.
                </p>

                <p class="colorFiord request__contentText">
                    Проверить свой часовой пояс можно по
                    <a href="#" target="_blank" class="link">ссылке</a>.
                </p>

                <div class="form">
                    <div class="request__buttons">
                        <Select class="request__input" :options="programsSelect" />
                        <Button
                            title="Подтвердить"
                            extraClass="button"
                            :onClick="() => onClose()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Select from "@/views/components/Select/Select";
export default {
    name: "timeZonePopup",
    components: { Button, Select },
    props: ["onClose"],
    data() {
        return {
            programsSelect: [
                {
                    value: "",
                    text: "Дубай (UTC +4.00) ",
                },
                {
                    value: "",
                    text: "Москва (UTC +3.00) ",
                },
                {
                    value: "",
                    text: "Киев (UTC +2.00)",
                    isSelected: true,
                },
                {
                    value: "",
                    text: "Париж (UTC +1.00)",
                },
                {
                    value: "",
                    text: "Лондон (UTC 0.00)",
                },
                {
                    value: "",
                    text: "Прая (UTC -1.00)",
                },
                {
                    value: "",
                    text: "Афины (UTC -2.00)",
                },
            ],
        };
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "timeZonePopup.scss";
</style>
