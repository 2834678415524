<template>
    <div class="popup-layout">
        <div class="popup popup--12">
            <div class="videoInstruction">
                <p class="h2 colorMirage videoInstruction__title">
                    Инструкция по обучению от Александра Герчика
                </p>
                <p class="colorYellow videoInstruction__subtitle">
                    Обязательно посмотрите это видео перед стартом обучения
                </p>
                <iframe
                    class="videoInstruction__video"
                    width="100%"
                    height="100%"
                    src="https://player.vimeo.com/video/650003199"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                <div class="videoInstruction__footer">
                    <Button
                        title="продолжить"
                        extraClass="button"
                        :onClick="() => onClose()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "videoInstructionPopup",
    components: { Button },
    props: ["onClose"],
    data() {
        return {
            checkboxValue: "",
        };
    },

    methods: {
        handleClickCheckbox() {
            this.checkboxValue = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "videoInstructionPopup.scss";
</style>
