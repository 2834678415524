<template>
    <div class="popup-layout">
        <div class="popup popup--10 welcomePopup">
            <img
                class="welcomePopup__img"
                alt="done"
                src="@/assets/img/pages/dashboard/welcome2.png"
            />
            <div class="welcome">
                <p class="h2 welcome__title">
                    Приветствую Вас на дистанционном курсе "Трейдинг от А до Я" 3.5
                </p>

                <p class="welcome__description">
                    Мы разработали обновленный формат самой популярной программы "Трейдинг
                    от А до Я" с акцентом на удобство процесса обучения и надеемся, что
                    вам все понравится.
                </p>
                <Button
                    title="Перейти к правилам курса"
                    extraClass="button"
                    :onClick="() => onClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "welcomePopup",
    components: { Button },
    props: ["onClose"],
    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "welcomePopup.scss";
</style>
