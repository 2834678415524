<template>
    <div class="popup-layout popup-layout--block">
        <div class="popup popup--9">
            <div class="regulations">
                <p
                    v-if="
                        $store.getters.getRules &&
                            $store.getters.getRules.data &&
                            $store.getters.getRules.data.title
                    "
                    class="h2 colorMirage regulations__title"
                >
                    {{ $store.getters.getRules.data.title }}
                </p>
                <div
                    v-if="
                        $store.getters.getRules &&
                            $store.getters.getRules.data &&
                            $store.getters.getRules.data.text
                    "
                    class="colorFiord singleContent popup__scrollContent customScroll"
                    v-html="
                        $store.getters.getRules.data.text.replaceAll(
                            '../../../uploads',
                            $oldClientTestUrl + 'uploads'
                        )
                    "
                ></div>
                <div class="regulations__footer">
                    <label
                        class="radioClear"
                        v-if="
                            this.$route.fullPath !==
                                '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true'
                        "
                    >
                        <input
                            class="input--checkboxLight"
                            type="checkbox"
                            name="first"
                            @click="() => handleClickCheckbox()"
                        />
                        <span>Я ознакомился с информацией о курсе</span>
                    </label>
                    <Button
                        :isDisabled="
                            !checkboxValue &&
                                this.$route.fullPath !==
                                    '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true'
                        "
                        title="принять и продолжить"
                        extraClass="button"
                        :onClick="() => onClose()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "regulationsPopup",
    components: { Button },
    props: ["onClose"],

    async beforeMount() {
        this.updateRulesStore();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateRulesStore();
        },
    },

    data() {
        return {
            checkboxValue: false,
        };
    },

    computed: {
        isCurrentStatus() {
            return (
                this.$store.getters.getDashboardActivityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member
                    .status
            );
        },
    },

    methods: {
        handleClickCheckbox() {
            this.checkboxValue = !this.checkboxValue;
        },

        async updateRulesStore() {
            const getRulesData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/rules"
            );
            if (getRulesData && getRulesData.data && getRulesData.data.result) {
                await this.$store.dispatch("setRules", {
                    data: getRulesData.data.rules,
                });
            } else {
                console.error(getRulesData.data.error);
                await this.$store.dispatch("clearRules");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "regulationsPopup.scss";
</style>
