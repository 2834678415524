var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layout popup-layout--block"},[_c('div',{staticClass:"popup popup--9"},[_c('div',{staticClass:"regulations"},[(
                    _vm.$store.getters.getRules &&
                        _vm.$store.getters.getRules.data &&
                        _vm.$store.getters.getRules.data.title
                )?_c('p',{staticClass:"h2 colorMirage regulations__title"},[_vm._v(" "+_vm._s(_vm.$store.getters.getRules.data.title)+" ")]):_vm._e(),(
                    _vm.$store.getters.getRules &&
                        _vm.$store.getters.getRules.data &&
                        _vm.$store.getters.getRules.data.text
                )?_c('div',{staticClass:"colorFiord singleContent popup__scrollContent customScroll",domProps:{"innerHTML":_vm._s(
                    _vm.$store.getters.getRules.data.text.replaceAll(
                        '../../../uploads',
                        _vm.$oldClientTestUrl + 'uploads'
                    )
                )}}):_vm._e(),_c('div',{staticClass:"regulations__footer"},[(
                        this.$route.fullPath !==
                            '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true'
                    )?_c('label',{staticClass:"radioClear"},[_c('input',{staticClass:"input--checkboxLight",attrs:{"type":"checkbox","name":"first"},on:{"click":function () { return _vm.handleClickCheckbox(); }}}),_c('span',[_vm._v("Я ознакомился с информацией о курсе")])]):_vm._e(),_c('Button',{attrs:{"isDisabled":!_vm.checkboxValue &&
                            this.$route.fullPath !==
                                '/trejding_ot_a_do_ya_3_5/dashboard?isTutorial=true',"title":"принять и продолжить","extraClass":"button","onClick":function () { return _vm.onClose(); }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }