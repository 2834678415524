<template>
    <div class="popup-layout popup-layout--block">
        <div class="popup popup--2 courseEvaluationModal">
            <button type="button" class="popup__close" @click="onClose"></button>

            <div v-if="!isVisibleAnswer">
                <p class="h2 popup__title ">
                    Поздравляю!
                </p>
                <p class="popup__text colorFiord">
                    Вы проделали долгий путь, приложили максимум усилий и дошли до конца
                    обучения в программе. Поделитесь пожалуйста своим мнением на счет
                    качества обучения ниже
                </p>
            </div>

            <div class="form form--3 courseEvaluationModalForm" v-if="!isVisibleAnswer">
                <div class="formgroup courseEvaluationModalFormgorup">
                    <label class="formgroup__label"
                        >Как Вы оцениваете пройденное обучение?</label
                    >
                    <div class="lessonEvaluationCard">
                        <p
                            :class="
                                evaluationValue === '$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--bad lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content lessonEvaluationCard__content--bad'
                            "
                            @click="() => handleClickGrade('$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Бесполезный
                            </span>
                        </p>
                        <p
                            :class="
                                evaluationValue === '$$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content'
                            "
                            @click="() => handleClickGrade('$$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Нормальный
                            </span>
                        </p>
                        <p
                            :class="
                                evaluationValue === '$$$'
                                    ? 'lessonEvaluationCard__content lessonEvaluationCard__content--good lessonEvaluationCard__content--active'
                                    : 'lessonEvaluationCard__content lessonEvaluationCard__content--good'
                            "
                            @click="() => handleClickGrade('$$$')"
                        >
                            <span class="lessonEvaluationCard__contentImages">
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                                <inline-svg :src="iconDollar"></inline-svg>
                            </span>
                            <span class="lessonEvaluationCard__contentText">
                                Полезный
                            </span>
                        </p>
                    </div>
                </div>

                <div class="formgroup courseEvaluationModalFormgorup">
                    <label class="formgroup__label">
                        Все ли Вам было понятно во время прохождения программы?
                    </label>
                    <div class="radioClearGroup">
                        <label class="radioClear">
                            <input
                                class="input--checkbox"
                                type="radio"
                                @click="() => setFirstCheck('Да')"
                                :checked="firstCheck === 'Да'"
                            />
                            <span>Да</span>
                        </label>
                        <label class="radioClear">
                            <input
                                class="input--checkbox"
                                type="radio"
                                @click="() => setFirstCheck('Нет')"
                                :checked="firstCheck === 'Нет'"
                            />
                            <span>Нет</span>
                        </label>
                    </div>
                </div>

                <div class="formgroup courseEvaluationModalFormgorup">
                    <label class="formgroup__label">
                        Была ли для Вас полезна эта программа?
                    </label>
                    <div class="radioClearGroup">
                        <label class="radioClear">
                            <input
                                class="input--checkbox"
                                type="radio"
                                @click="() => setSecondCheck('Да')"
                                :checked="secondCheck === 'Да'"
                            />
                            <span>Да</span>
                        </label>
                        <label class="radioClear">
                            <input
                                class="input--checkbox"
                                type="radio"
                                @click="() => setSecondCheck('Нет')"
                                :checked="secondCheck === 'Нет'"
                            />
                            <span>Нет</span>
                        </label>
                    </div>
                </div>

                <div class="formgroup courseEvaluationModalFormgorup">
                    <label class="formgroup__label">
                        Подскажите, что нам стоит изменить, чтобы сделать программу лучше?
                    </label>
                    <TextArea
                        rows="5"
                        placeholder="Введите Ваш ответ"
                        :isError="isDescriptionLong"
                        errorText="Вы не можете отправить более 1000 символов в этом текстовом поле"
                        :optimalTextLength="200"
                        optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                        :onChange="(event) => onChangeTextArea(event)"
                    />
                </div>

                <!-- prettier-ignore -->
                <div class="form__submit">
                    <Button
                        :isDisabled="
                            (
                                !evaluationValue ||
                                !firstCheck ||
                                !secondCheck ||
                                !badOptionText
                            )
                        "
                        title="Отправить"
                        extraClass="button"
                        :onClick="() => chooseProgram()"
                    />
                </div>
            </div>

            <div v-if="isVisibleAnswer" class="lessonEvaluation">
                <p class="profileBox__title2">
                    Ваш ответ отправлен!
                </p>
                <img
                    class="lessonEvaluation__img"
                    alt="foldedHands"
                    src="@/assets/img/pages/study/lessonEvaluation/foldedHands.png"
                />
                <p class="profileBox__text2">
                    Спасибо за оценку!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/views/components/Input/Input";
import TextArea from "@/views/components/TextArea/TextArea";
import Button from "@/views/components/Button/Button";

import iconDollar from "../../../../../../assets/img/pages/study/lessonEvaluation/dollar.svg";

export default {
    name: "courseEvaluationModal",
    components: { Input, TextArea, Button },
    props: ["onClose"],
    data() {
        return {
            iconDollar,
            isVisibleAnswer: false,
            isDescriptionLong: false,
            evaluationValue: "",
            badOptionText: "",
            firstCheck: "",
            secondCheck: "",
        };
    },

    methods: {
        onSendAnswer() {
            this.isVisibleAnswer = true;
        },

        handleClickGrade(value) {
            this.evaluationValue = value;
        },

        onChangeTextArea(event) {
            this.badOptionText = event;
        },

        setFirstCheck(value) {
            this.firstCheck = value;
        },

        setSecondCheck(value) {
            this.secondCheck = value;
        },

        chooseProgram: async function() {
            const bodyByEvaluationValue = {
                value: this.evaluationValue,
                text: this.badOptionText,
                options: [
                    {
                        text: "Все ли Вам было понятно во время прохождения программы?",
                        value: this.firstCheck.toLowerCase(),
                    },
                    {
                        text: "Была ли для Вас полезна эта программа?",
                        value: this.secondCheck.toLowerCase(),
                    },
                ],
            };
            if (this.badOptionText.length > 1000) {
                this.isDescriptionLong = true;
            } else {
                this.isDescriptionLong = false;
            }
            if (this.badOptionText.length < 1000) {
                const response = await this.axios.post(
                    `/programs/${this.$route.params.slug}/evaluation`,
                    bodyByEvaluationValue
                );
                if (response && response.data && response.data.result) {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Ваша оценка успешно отправлена",
                        },
                    });
                    this.updateLessonEvaluation();
                    this.onClose();
                } else {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Не удалось отправить оценку, попробуйте позже",
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "courseEvaluationModal.scss";
</style>
