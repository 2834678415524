import { render, staticRenderFns } from "./welcomePopup.vue?vue&type=template&id=aaf773ea&scoped=true&"
import script from "./welcomePopup.vue?vue&type=script&lang=js&"
export * from "./welcomePopup.vue?vue&type=script&lang=js&"
import style0 from "./welcomePopup.vue?vue&type=style&index=0&id=aaf773ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf773ea",
  null
  
)

export default component.exports