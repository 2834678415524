<template>
    <div>
        <select
            :value="inputValue"
            :class="isError ? 'input--error' : 'input'"
            @change="(event) => onChangeInput(event)"
        >
            <option :v-if="placeholder" disabled="disabled" selected="true" value="">{{
                placeholder
            }}</option>
            <option
                v-for="item in options"
                :key="item.options"
                :value="item.value"
                :selected="item.isSelected"
                >{{ item.text }}</option
            >
        </select>
        <p class="h5 error">{{ isError ? errorText : "" }}</p>
    </div>
</template>

<script>
export default {
    props: {
        inputValue: String,
        placeholder: String,
        isError: Boolean,
        errorText: String,
        onChange: Function,
        options: {
            value: String,
            text: String,
            isSelected: Boolean,
        },
    },

    methods: {
        onChangeInput(event) {
            this.onChange(event.target.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./Select.scss";
</style>
