<template>
    <div>
        <router-view></router-view>

        <WelcomePopup
            v-if="isVisibleModalWelcome"
            :onClose="() => showedWelcomePopup()"
        />

        <RegulationsPopup
            v-if="isVisibleModalRegulations"
            :onClose="() => acceptedAgreement()"
        />

        <VideoInstructionPopup
            v-if="isVisibleModalVideoInstruction"
            :onClose="() => showedWelcomeVideo()"
        />

        <DashboardAchievementsModal
            v-if="isVisibleModalAchivements"
            :achievementsIcon="
                `${$oldClientTestUrl.slice(0, -1) + achievementsList.icon}`
            "
            :achievementsTitle="achievementsList.name"
            :achievementsDescription="achievementsList.description"
            :achievementsRarity="achievementsList.howManyHaveAchievement"
            :onClose="() => onCloseModalAchivements()"
        />

        <TimeZonePopup v-if="isVisibleModalTimeZone" :onClose="() => {}" />

        <CourseEvaluationModal
            v-if="
                isVisibleModalCourseEvaluation &&
                    evaluationCourseState &&
                    !evaluationCourseResult
            "
            :onClose="() => onCloseModalCourseEvaluation()"
        />
    </div>
</template>

<script>
import WelcomePopup from "@/views/pages/dashboard/welcomePopup/welcomePopup";
import RegulationsPopup from "@/views/pages/dashboard/regulationsPopup/regulationsPopup";
import VideoInstructionPopup from "@/views/pages/dashboard/videoInstructionPopup/videoInstructionPopup";
import TimeZonePopup from "@/views/pages/dashboard/timeZonePopup/timeZonePopup";
import DashboardAchievementsModal from "@/views/pages/dashboard/dashboardAchievements/dashboardAchievementsModal/dashboardAchievementsModal";
import CourseEvaluationModal from "@/views/pages/study/studyLesson/lessonEvaluation/courseEvaluationModal/courseEvaluationModal";

export default {
    name: "course",

    components: {
        TimeZonePopup,
        WelcomePopup,
        RegulationsPopup,
        VideoInstructionPopup,
        DashboardAchievementsModal,
        CourseEvaluationModal,
    },

    async beforeMount() {
        this.updateMemberStore();
        this.updateMemberConfirmationsStore();
        this.updateDashboardAchievement();
        if (this.$route.params.slug !== "trejding_ot_a_do_ya_3_5") {
            window.location.href =
                "https://edu.gerchik.ru/programs/" + this.$route.params.slug;
        }

        if (!this.usedeskChatToken) {
            const getUsedeskTokenInterval = setInterval(() => {
                if (window.usedeskMessenger) {
                    const token = window.usedeskMessenger.getChatToken();

                    if (token) {
                        this.$store.dispatch("setUsedeskChatToken", token);
                        clearInterval(getUsedeskTokenInterval);
                    }
                }
            }, 5000);
        }
    },

    async mounted() {
        this.redirectToIntroSurvey();
        this.redirectToEntryTest();
        this.redirectToFinalTest();

        this.setUsedeskInfo();

        this.updateProgressStore();
        this.updateLessonEvaluation();
    },

    watch: {
        "$route.fullPath": function() {
            this.updateMemberStore();
            this.updateMemberConfirmationsStore();
            this.updateDashboardAchievement();

            this.redirectToIntroSurvey();
            this.redirectToEntryTest();
            this.redirectToFinalTest();

            this.updateProgressStore();
            this.updateLessonEvaluation();

            if (location.search.replace("?", "") === "isTutorial=true") {
                this.isModalWelcomeInfoReviewed = true;
            }

            if (this.$route.params.slug !== "trejding_ot_a_do_ya_3_5") {
                window.location.href =
                    "https://edu.gerchik.ru/programs/" + this.$route.params.slug;
            }
        },
        isRedirectToIntroSurvey: function() {
            this.redirectToIntroSurvey();
        },
        isRedirectToEntryTest: function() {
            this.redirectToEntryTest();
        },
        isRedirectToFinalTest: function() {
            this.redirectToFinalTest();
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        progress: function() {
            return (
                (this.$store.getters.getUserProgress &&
                    this.$store.getters.getUserProgress.progress &&
                    this.$store.getters.getUserProgress.progress.data &&
                    this.$store.getters.getUserProgress.progress.data[0].blocks) ||
                []
            );
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        isVisibleModalWelcome: function() {
            return (
                this.isLoadedConfirmations &&
                this.confirmations &&
                !this.confirmations.viewedWelcomePopup
            );
        },
        isVisibleModalRegulations: function() {
            return (
                (!this.isVisibleModalWelcome &&
                    this.member.agreementData &&
                    this.member.agreementData.showRules) ||
                this.isModalWelcomeInfoReviewed
            );
        },
        isVisibleModalVideoInstruction: function() {
            return (
                (this.confirmations.viewedWelcomePopup &&
                    !this.isVisibleModalRegulations &&
                    !this.confirmations.viewedWelcomeVideo) ||
                this.isModalWelcomeVideoReviewed
            );
        },

        isRedirectToIntroSurvey: function() {
            return (
                this.confirmations.viewedWelcomePopup &&
                !this.isModalWelcomeInfoReviewed &&
                !this.isModalWelcomeVideoReviewed &&
                !this.isVisibleModalVideoInstruction &&
                !this.isVisibleModalRegulations &&
                this.member.quizData &&
                this.member.quizData.showQuiz &&
                this.member.quizData.quiz &&
                this.member.quizData.quiz.trigger === "beforeStart"
            );
        },
        isRedirectToEntryTest: function() {
            return (
                this.confirmations.viewedWelcomePopup &&
                !this.isModalWelcomeInfoReviewed &&
                !this.isModalWelcomeVideoReviewed &&
                !this.isVisibleModalRegulations &&
                !(this.member.quizData && this.member.quizData.showQuiz) &&
                !this.isRedirectToIntroSurvey &&
                !(
                    this.member &&
                    this.member.passedEntranceTest &&
                    this.member.passedEntranceTest.isPassed
                )
            );
        },
        isRedirectToFinalTest: function() {
            return (
                !this.isRedirectToEntryTest && this.member && this.member.showFinalQuiz
            );
        },
        isVisibleModalTimeZone: function() {
            return false;
        },

        isVisibleModalAchivements: function() {
            return (
                this.$store.getters.getDashboardAchievement &&
                this.$store.getters.getDashboardAchievement.achievement &&
                this.$store.getters.getDashboardAchievement.achievement.activeNotShown &&
                this.$store.getters.getDashboardAchievement.achievement.activeNotShown
                    .length &&
                this.confirmations.viewedWelcomePopup &&
                !this.isModalWelcomeInfoReviewed &&
                !this.isModalWelcomeVideoReviewed &&
                this.confirmations.viewedOnBoardingDashboard
            );
        },

        achievementsList() {
            return this.$store.getters.getDashboardAchievement.achievement
                .activeNotShown[0];
        },

        usedeskChatToken() {
            return this.$store.getters.getUsedeskChatToken || "";
        },

        evaluationCourseState() {
            return (
                (this.progress &&
                    this.progress.length &&
                    this.progress[8] &&
                    this.progress[8].lessons &&
                    this.progress[8].lessons.length &&
                    this.progress[8].lessons[0] &&
                    this.progress[8].lessons[0].lesson &&
                    this.progress[8].lessons[0].lesson.homework &&
                    ((this.progress &&
                        this.progress.length &&
                        this.progress[9] &&
                        this.progress[9].lessons &&
                        this.progress[9].lessons.length &&
                        this.progress[9].lessons[0] &&
                        this.progress[9].lessons[0].lesson &&
                        this.progress[9].lessons[0].lesson.homework) ||
                        !this.progress[9].lessons[0].hasAccesByAccesLevel)) ||
                false
            );
        },

        evaluationCourseResult() {
            return (
                (!this.isVisibleModalAchivements &&
                    this.$store.getters.getEvaluation.evaluation.courseEvaluation.name ===
                        this.$store.getters.getMember.data.courseName) ||
                false
            );
        },
    },

    data() {
        return {
            isLoadedConfirmations: false,
            backendError: "",
            isModalWelcomeInfoReviewed: false,
            isModalWelcomeVideoReviewed: false,
            isVisibleModalCourseEvaluation: true,
        };
    },

    methods: {
        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (getMemberData.data.code === "programs") {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                this.backendError = getMemberData.data.error;
                if (getMemberData.data.error === "Курс не найден") {
                    return (window.location.href = `${window.location.origin}/404`);
                }
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        endTime: 8000,
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "У Вас нет доступа к этому курсу"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async updateDashboardAchievement() {
            const getDashboardAchievement = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/achievements"
            );

            if (
                getDashboardAchievement &&
                getDashboardAchievement.data &&
                getDashboardAchievement.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardAchievement",
                    getDashboardAchievement.data
                );
            } else {
                console.error(
                    "Ошибка при поиске достижений: " +
                        (getDashboardAchievement.data.message || "")
                );
                await this.$store.dispatch("clearDashboardAchievement");
            }
        },

        async showedWelcomePopup() {
            await this.axios.post(
                "/programs/" +
                    this.$route.params.slug +
                    "/confirmations?confirmation=viewedWelcomePopup"
            );
            this.updateMemberStore();
            this.updateMemberConfirmationsStore();
        },

        async acceptedAgreement() {
            if (
                !this.isVisibleModalWelcome &&
                this.member.agreementData &&
                this.member.agreementData.showRules
            ) {
                await this.axios.get(
                    "/programs/" + this.$route.params.slug + "/rules/accept"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
                this.updateDashboardAchievement();
            } else {
                this.isModalWelcomeInfoReviewed = false;
                this.isModalWelcomeVideoReviewed = true;
            }
        },

        async showedWelcomeVideo() {
            if (
                !this.isVisibleModalRegulations &&
                !this.confirmations.viewedWelcomeVideo
            ) {
                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/confirmations?confirmation=viewedWelcomeVideo"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
            } else {
                this.isModalWelcomeVideoReviewed = false;
                this.$store.dispatch("setIsReviewOnBoard", true);
            }
        },

        async onCloseModalAchivements() {
            const achivementBody = {
                achievementId: this.achievementsList._id,
            };
            await this.axios.post(
                "/programs/" + this.$route.params.slug + "/study/shown-achievement",
                achivementBody
            );
            this.updateDashboardAchievement();
        },

        redirectToIntroSurvey() {
            if (this.member && this.member.member__id) {
                setTimeout(() => {
                    if (
                        this.isRedirectToIntroSurvey &&
                        this.$route.path.indexOf("/survey") === -1
                    ) {
                        this.$router.push(
                            "/" + this.$route.params.slug + "/dashboard/survey"
                        );
                    }
                }, 1000);
            }
        },

        redirectToEntryTest() {
            if (this.member && this.member.member__id) {
                setTimeout(() => {
                    if (
                        this.isRedirectToEntryTest &&
                        this.$route.path.indexOf("/opening-test") === -1
                    ) {
                        this.$router.push(
                            "/" + this.$route.params.slug + "/dashboard/opening-test"
                        );
                    }
                }, 1000);
            }
        },

        redirectToFinalTest() {
            if (this.member && this.member.member__id) {
                setTimeout(() => {
                    if (
                        this.isRedirectToFinalTest &&
                        this.$route.path.indexOf("/final-test") === -1
                    ) {
                        this.$router.push(
                            "/" + this.$route.params.slug + "/dashboard/final-test"
                        );
                    }
                }, 1000);
            }
        },

        setUsedeskInfo() {
            const userUsedeskInfo = {
                name: `${(this.member.user && this.member.user.firstName) || ""} ${(this
                    .member.user &&
                    this.member.user.lastName) ||
                    ""}`,
                email: `${(this.member.user && this.member.user.email) || ""}`,
                phone: `${(this.member.user && this.member.user.phone) || ""}`,
            };

            if (this.usedeskChatToken) {
                userUsedeskInfo.token = this.usedeskChatToken;
            }

            window.__widgetInitCallback = function(widget) {
                const usedeskWidget = window.usedeskMessenger;

                if (usedeskWidget) {
                    usedeskWidget.identify(userUsedeskInfo);
                    usedeskWidget.userIdentify(userUsedeskInfo);
                }
            };
        },

        async updateProgressStore() {
            this.isLoadingStudyData = true;
            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске основных блоков: " + getProgress.data.error
                );
                await this.$store.dispatch("clearUserProgress");
            }

            this.isLoadingStudyData = false;
        },

        async updateLessonEvaluation() {
            const getEvaluation = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/evaluation"
            );

            if (getEvaluation && getEvaluation.data && getEvaluation.data.result) {
                await this.$store.dispatch("setEvaluation", getEvaluation.data);
            } else {
                console.error(
                    "Ошибка при поиске оценки урока: " + (getEvaluation.data.error || "")
                );
                await this.$store.dispatch("clearEvaluation");
            }
        },

        onCloseModalCourseEvaluation() {
            this.isVisibleModalCourseEvaluation = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "course.scss";
</style>
